<template>
	<div id="app">
		<!-- redirect -->
	</div>
</template>

<script>


export default {
	name: "home",
	mounted() {
		console.log(process.env);
	},
};
</script>
